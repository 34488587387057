import PropTypes from 'prop-types'
import React from 'react'
import { Link } from "gatsby"
import ReactGA from 'react-ga';

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* <span className="icon fa-skull-crossbones"></span> */}
    </div>
    <div className="content">
      <div className="inner">
        <h1>The Undead Dev</h1>
        <p>
          Full Stack Dev - Game Dev - VR Enthusiast
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('games')
            }}
          >
            Games
          </button>
        </li>
        <li>
          {/* <button
            onClick={() => {
              
            }}
          >
            Blog
          </button> */}
          <Link to="/blog/1" className="button" >Blog</Link>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

const trackingId = "UA-38716656-3"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);


Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
