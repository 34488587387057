import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/bg_Undead.jpg'
import Avbas_logo from '../images/Avbas_logo.png'
import WAI_SS_01 from '../images/WAI_SS_01.png'
import GJams from '../images/GameJam_Collection.jpg'
import { Link, navigate } from "gatsby"

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )


    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Hi, my name is Robby. I'm a developer, a VR enthusiast and a tech enthusiast.
          </p>
          {close}
        </article>

        <article
          id="games"
          className={`${this.props.article === 'games' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">My Games</h2>
          <h3 className="major">Untitled WIP</h3>
          <span className="image main">
            <img src={WAI_SS_01} alt="" />
          </span>
          <p>
            I'm currently working on a narrative based VR horror puzzle game about trying to recover from a brain injury. More information about this project soon.
          </p>

          <h3 className="major">A.V.B.A.S.</h3>
          <span className="image main">
            <img src={Avbas_logo} alt="" />
          </span>
          <p>
            AVBAS is a two-part experiment in story telling across media formats. The first part is a series of posts to the horror based "nosleep" subreddit about a young man's experiences with a piece of evil self-hypnosis virtual reality software, and the second part is the virtual reality software itself. AVBAS tells the tale of a young intern who begins to fear the software the company he works for might have negative effects on its test subjects. Over the course of three posts the intern goes from fearing the software to succumbing to its hypnotic effects himself, culminating in him releasing the software to the world. The VR portion of AVBAS allows the player to continue the story by seeing and experiencing the hypnosis effects first hand. The game uses a series of actual hypnotic induction techniques mixed with subconscious cues unsettling scenarios to create a new type of horror experience.
          </p>
          <p>
            <a href="https://avbas-intern.itch.io/avbas">
              <span className="label">More information here</span>
            </a>
          </p>

          <h3 className="major">Game Jams</h3>
          <span className="image main">
            <img src={GJams} alt="" />
          </span>
          <p>
            I also frequently participate in time restricted game jams such as Ludum Dare and Global Game Jam.
          </p>
          <p>
            <a href="https://jimmothysanchez.itch.io/">
              <span className="label">These games can be found here</span>
            </a>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="POST" data-netlify="true" action="/thank-you" data-netlify-honeypot="bot-field" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <Link to={"https://twitter.com/TheUndeadDev"}>
                <i class="fab fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link to={"https://www.facebook.com/UndeadDev"}>
                <i class="fab fa-facebook"></i>
              </Link>
            </li>
            <li>
              <Link to={"https://www.instagram.com/the.undead.dev"}>
                <i class="fab fa-instagram"></i>
              </Link>
            </li>
            <li>
              <Link to={"https://github.com/JimmothySanchez"}>
                <i class="fab fa-github"></i>
              </Link>
            </li>
            <li>
              <Link to={"https://www.linkedin.com/in/robert-ciszek-2a92444/"}>
                <i class="fab fa-linkedin"></i>
              </Link>
            </li>
            <li>
              <Link to={"https://jimmothysanchez.itch.io/"}>
                <i class="fab fa-itch-io"></i>
              </Link>
            </li>
            <li>
              <Link to={"https://www.youtube.com/channel/UCAZ6EFXA4jj3vfbo2G6JxvQ"}>
                <i class="fab fa-youtube"></i>
              </Link>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
